import { Component } from 'react';
import { connect } from 'react-redux';
import Head from 'next/head';
import Notification from '@/components/Base/Notification';
import { FullPage } from './FullPage';
import { withErrorBoundary } from '@/utils/errors';

class WebpageAEG extends Component {
  render() {
    const { webpageUsername } = this.props;
    const {
      email,
      corporation: { faqs, latest_news }
    } = this.props.info;
    const {
      about_me,
      personal_photo,
      first_name,
      last_name,
      cell_phone,
      phone,
      phone_ext,
      agent_licenses,
      title,
      address_1,
      address_2,
      city,
      state,
      zip_code,
      facebook,
      twitter,
      instagram,
      linkedin,
      google,
      youtube,
      yelp,
      office_hours,
      languages_spoken
    } = this.props.info.profile;

    const profileInfo = {
      webpageUsername,
      email,
      about_me,
      personal_photo,
      first_name,
      last_name,
      cell_phone,
      phone,
      phone_ext,
      agent_licenses,
      address_1,
      address_2,
      city,
      state,
      zip_code,
      title,
      facebook,
      twitter,
      instagram,
      linkedin,
      google,
      youtube,
      yelp,
      office_hours,
      languages_spoken,
      faqs,
      latest_news
    };
    return (
      <>
        <Head>
          <title>{`${first_name} ${last_name} | American Republic Insurance Services®`}</title>
        </Head>
        <FullPage profileInfo={profileInfo} />
        <div style={{ fontSize: '1.4rem', width: '100%' }}>
          <Notification />
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  info: state.public.info,
  additional_info: state.public
});

export default connect(mapStateToProps)(withErrorBoundary(WebpageAEG));
