import { memo } from 'react';
import styles from './CTACallLink.module.scss';

export const CTACallLink = memo(({ phoneNumber }) => {
  return (
    <a className={styles.ctaCallLink} href={`tel:${phoneNumber?.anchor}`}>
      Or call me at {phoneNumber?.label}
    </a>
  );
});
