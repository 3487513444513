import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import styles from './ForbiddenWebpage.module.scss';

const ForbiddenWebpage = () => {
  return (
    <div className={styles.ForbiddenWebpage}>
      <p className={styles.ForbiddenWebpageText}>This agent's site is no longer active</p>
    </div>
  );
};

export default withErrorBoundary(ForbiddenWebpage);
