import { memo } from 'react';
import styles from './RequestAppointment.module.scss';

export const RequestAppointment = memo(({ children }) => {
  return (
    <div className={styles.requestContainer}>
      <h2 className={styles.requestHeading}>Let's Get You Covered</h2>
      <h3 className={styles.requestSubHeading}>
        I'll work with you to find a plan that suits your needs - and your budget.
      </h3>
      {children}
    </div>
  );
});
