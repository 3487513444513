import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { subscribe, unsubscribe } from 'pubsub-js';
import classNames from 'classnames';
import { humanizeString, setStateAsync } from '@/utils/helpers';

class Notification extends Component {
  state = {
    notificationVisible: false,
    type: '',
    data: ''
  };

  setStateAsync = setStateAsync.bind(this);

  componentDidMount() {
    subscribe('error', this.setNotification);
    subscribe('success', this.setNotification);
  }

  componentWillUnmount() {
    unsubscribe(this.setNotification);
  }

  setNotification = async (type, res) => {
    await this.setStateAsync({ notificationVisible: false });
    let data;
    if (typeof res === 'string') {
      data = res;
    }
    if (typeof res === 'object') {
      if (res.jsonResponse) data = res.jsonResponse.errors || res.jsonResponse.error;
    }
    this.setState({ type, data, notificationVisible: data ? true : false });
  };

  get data() {
    if (typeof this.state.data === 'string') {
      return <p>{this.state.data}</p>;
    } else if (typeof this.state.data === 'object') {
      return Object.keys(this.state.data).map(key => {
        return (
          <div key={key}>
            <p>{humanizeString(key.split('.').join(' '))}</p>
            <ul>
              {this.state.data[key].map((el, index) => (
                <li key={index}>{el}</li>
              ))}
            </ul>
          </div>
        );
      });
    } else {
      return null;
    }
  }

  get notificationMessage() {
    return (
      <div
        onClick={() => this.setState({ notificationVisible: false })}
        className={`notification-wrapper ${this.state.type}`}
      >
        <p className="heading">{this.state.type}</p>
        {this.data}
      </div>
    );
  }

  render() {
    return (
      <div className={classNames('notification-container', this.props.classNames)}>
        {this.state.notificationVisible && this.notificationMessage}
      </div>
    );
  }
}

export default withErrorBoundary(Notification);
