import { useCallback, useState } from 'react';
import { publish as publishFormEvent } from 'pubsub-js';
import isEmail from 'validator/lib/isEmail';
import { publish } from '@/utils/publish';
import { RESET_FORMS_EVENT } from '../../utils/constants';

export const useAppointmentForm = ({ onCreateContact }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '', products: [] });
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    products: false
  });

  const onSelectChange = useCallback(products => {
    setFormData(prevState => ({ ...prevState, products }));
    setErrors(prevState => ({ ...prevState, products: false }));
  }, []);

  const onInputChange = useCallback(e => {
    setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    setErrors(prevState => ({ ...prevState, [e.target.name]: false }));
  }, []);

  const isFormValid = useCallback(formData => {
    const newErrors = {
      firstName: !formData.firstName,
      lastName: !formData.lastName,
      email: !formData.email || !isEmail(formData.email),
      products: !formData.products.length
    };

    setErrors(newErrors);

    const hasError = Object.values(newErrors).some(error => error);
    if (hasError) {
      return false;
    }
    return true;
  }, []);

  const resetForm = useCallback(() => {
    setFormData({ firstName: '', lastName: '', email: '', products: [] });
    setErrors({ firstName: false, lastName: false, email: false, products: false });
  }, []);

  const onFormSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (isFormValid(formData)) {
        const body = {
          first_name: formData.firstName.trim(),
          last_name: formData.lastName.trim(),
          email: formData.email.toLowerCase().trim(),
          phone: '',
          products: formData.products.map(product => product.label).join(', '),
          type: 'request_appointment'
        };

        try {
          setIsSubmitting(true);
          await onCreateContact(body);
          publishFormEvent(RESET_FORMS_EVENT);
          setIsSubmitting(false);
        } catch (ex) {
          publish('error', ex);
          setIsSubmitting(false);
        }
      }
    },
    [isFormValid, formData, onCreateContact]
  );

  return {
    formData,
    errors,
    isSubmitting,
    onInputChange,
    onSelectChange,
    onFormSubmit,
    resetForm
  };
};
