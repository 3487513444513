import { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { ArticleCard } from './ArticleCard';
import sideArrowRightIcon from '../../assets/icons/Side_Arrow_Icon_Right.png';
import sideArrowLeftIcon from '../../assets/icons/Side_Arrow_Icon_Left.png';
import styles from './LatestNews.module.scss';

const calculateItemsPerPage = (width = 0) => {
  if (width > 1280) {
    return 4;
  }
  if (width > 768) {
    return 3;
  }
  if (width > 640) {
    return 2;
  }
  return 1;
};

const TRANSITION_TIMEOUT = 100;
const DEFAULT_ICON_SIZE = 40;

export const LatestNews = memo(({ items = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(calculateItemsPerPage(window?.innerWidth));
  const [isVisible, setIsVisible] = useState(true);

  const paginatedArticles = items.slice(currentIndex, currentIndex + itemsPerPage);
  const placeholderArticles = Array.from({ length: itemsPerPage - paginatedArticles.length });
  const showPrevious = currentIndex > 0;
  const showNext = currentIndex + itemsPerPage < items.length;

  const handlePrevious = () => {
    if (showPrevious) {
      setIsVisible(false);

      window?.setTimeout(() => {
        setCurrentIndex(prevIndex => {
          const previousIndex = prevIndex - itemsPerPage;
          return previousIndex >= 0 ? previousIndex : 0;
        });
        setIsVisible(true);
      }, TRANSITION_TIMEOUT);
    }
  };

  const handleNext = () => {
    if (showNext) {
      setIsVisible(false);

      window?.setTimeout(() => {
        setCurrentIndex(prevIndex => {
          const nextIndex = prevIndex + itemsPerPage;
          return nextIndex < items.length ? nextIndex : prevIndex;
        });
        setIsVisible(true);
      }, TRANSITION_TIMEOUT);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setItemsPerPage(calculateItemsPerPage(window?.innerWidth));
    };
    window?.addEventListener('resize', handleResize);
    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className={styles.newsContainer}>
      <h3 className={styles.newsHeading}>Latest News and Resources</h3>

      <div className={classNames(styles.blogCardsContainer, { [styles.visible]: isVisible })}>
        {showPrevious && (
          <button type="button" className={styles.readMoreIconLeft} onClick={handlePrevious}>
            <Image alt="View Previous" src={sideArrowLeftIcon} width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />
          </button>
        )}

        {paginatedArticles.map((article, index) => (
          <ArticleCard key={article.id || index} article={article} />
        ))}

        {placeholderArticles.map((_, index) => (
          <article key={index} className={styles.emptyBlogCard} />
        ))}

        {showNext && (
          <button type="button" className={styles.readMoreIconRight} onClick={handleNext}>
            <Image alt="View Next" src={sideArrowRightIcon} width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />
          </button>
        )}
      </div>
    </section>
  );
});
