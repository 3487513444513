import { memo } from 'react';
import ReactSelect from 'react-select';
import { PRODUCTS_OFFERED } from '../../../utils/constants';
import styles from './ProductSelectInput.module.scss';

const ARIS_BLUE = '#3a6f8f';
const productOptions = PRODUCTS_OFFERED.map(product => ({ value: product.value, label: product.label }));

export const ProductSelectInput = memo(({ value, onChange, hasError, isBottom = false }) => {
  return (
    <ReactSelect
      options={productOptions}
      isSearchable={false}
      isMulti={true}
      aria-label="Select Products"
      name="products"
      classNamePrefix="aris"
      onChange={product => onChange(product)}
      value={value}
      isClearable={false}
      placeholder="Select Products"
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        textAlign: 'left',
        colors: {
          ...theme.colors,
          primary: isBottom ? '#fff' : ARIS_BLUE,
          primary25: isBottom ? '#fff' : ARIS_BLUE
        }
      })}
      styles={{
        container: (base, state) => ({
          ...base,
          width: '100%',
          boxSizing: 'border-box',
          border: 'none',
          ':hover': {
            outline: isBottom ? `0.2rem solid #fff` : `0.2rem solid ${ARIS_BLUE}`,
            outlineOffset: isBottom ? '0.2rem' : '0'
          }
        }),
        control: (base, state) => {
          let outlineColor = '';
          if (state.isFocused) {
            outlineColor = isBottom ? '#fff' : ARIS_BLUE;
          } else if (hasError && !state.isFocused) {
            outlineColor = 'red';
          }

          let outlineType = '';
          if (hasError || state.isFocused) {
            outlineType = 'solid';
          } else {
            outlineType = 'none';
          }

          return {
            ...base,
            width: '100%',
            minHeight: '4.6rem',
            backgroundColor: state.isFocused ? '#ededed' : '#fff',
            outline: `0.2rem ${outlineType} ${outlineColor}`,
            outlineOffset: isBottom && state.isFocused ? '0.2rem' : 0,
            border: 'none !important'
          };
        },
        placeholder: (base, state) => ({
          ...base,
          color: ARIS_BLUE,
          textAlign: 'left',
          fontSize: '2rem',
          marginLeft: 0
        }),
        valueContainer: (base, state) => ({
          ...base,
          minHeight: '4.6rem',
          width: '100%',
          padding: '0 0 0 1.2rem'
        }),
        option: (base, state) => {
          return {
            ...base,
            textAlign: 'left',
            fontSize: '2rem',
            color: state.isFocused ? '#fff' : ARIS_BLUE,
            backgroundColor: state.isFocused ? ARIS_BLUE : '#fff',

            ':active': {
              ...base[':active'],
              backgroundColor: ARIS_BLUE,
              color: '#fff',
              filter: 'brightness(1.25)'
            }
          };
        },
        multiValue: (base, state) => ({
          ...base,
          fontSize: '2rem',
          backgroundColor: 'transparent',
          fontFamily: "'DIN', 'Poppins', sans-serif"
        }),
        multiValueLabel: (base, state) => ({
          ...base,
          color: ARIS_BLUE,
          paddingLeft: 0,
          fontSize: '2rem'
        }),
        indicatorSeparator: (base, state) => ({
          ...base,
          visibility: 'hidden'
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          color: ARIS_BLUE,
          height: '4.2rem',
          width: '4.2rem',
          ':hover': {
            color: ARIS_BLUE
          }
        }),
        noOptionsMessage: (base, state) => ({
          ...base,
          fontSize: '2rem',
          color: ARIS_BLUE
        })
      }}
      classNames={{
        container: state => styles.arisSelectContainer,
        control: state => styles.arisSelectControl,
        placeholder: state => styles.arisSelectPlaceholder,
        input: state => styles.arisSelectInput,
        valueContainer: state => styles.arisValueContainer,
        dropdownIndicator: state => styles.arisSelectDropdownIndicator,
        option: state => styles.arisSelectOption,
        multiValue: state => styles.arisMulti,
        multiValueLabel: state => styles.arisMultiValueLabel,
        noOptionsMessage: state => styles.arisNoOptionsMessage
      }}
    />
  );
});
