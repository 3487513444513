import { memo, useMemo } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { SocialMediaIconList } from '../SocialMediaIconList';
import smallEmailIcon from '../../assets/icons/Small_Let_s_Connect_Email_Icon.png';
import smallPhoneIcon from '../../assets/icons/Small_Let_s_Connect_Phone_Icon.png';
import smallAddressIcon from '../../assets/icons/Small_Let_s_Connect_Address_Icon.png';
import styles from './AgentDetails.module.scss';

const DEFAULT_ICON_SIZE = 40;

export const AgentDetails = memo(
  ({
    contactInfo = { email: '', phoneNumber: '', address: { lineOne: '', lineTwo: '' } },
    socialMedias = { facebook: '', instagram: '', linkedin: '', twitter: '', google: '', youtube: '', yelp: '' },
    officeHours = [],
    agentLicenses = [],
    languagesSpoken = []
  }) => {
    const { email, phoneNumber, address } = contactInfo;
    const { facebook, instagram, linkedin, twitter, google, youtube, yelp } = socialMedias;
    const isEmpty = useMemo(() => officeHours?.length === 1 && officeHours?.[0]?.timeRange === 'Closed', [officeHours]);

    return (
      <div className={styles.detailsContainer}>
        <div className={styles.columnOne}>
          <h4 className={styles.detailsHeading}>Office Hours</h4>
          {!isEmpty &&
            officeHours.map((entry, index) => (
              <div key={index} className={styles.officeHours}>
                <p className={styles.detailsContent}>{entry.days}</p>
                <p className={classNames(styles.detailsContent, styles.timeRange)}>{entry.timeRange}</p>
              </div>
            ))}
        </div>
        <div className={styles.columnTwo}>
          <div className={styles.connectWrapper}>
            <h4 className={styles.detailsHeading}>Let's Connect</h4>
            {email && (
              <div className={styles.connectItem}>
                <Image alt="Email" src={smallEmailIcon} width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />
                <p className={styles.detailsContent}>{email}</p>
              </div>
            )}
            {phoneNumber && (
              <div className={styles.connectItem}>
                <Image alt="Phone" src={smallPhoneIcon} width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />
                <p className={styles.detailsContent}>{phoneNumber}</p>
              </div>
            )}
            {address.lineOne && address.lineTwo && (
              <div className={classNames(styles.connectItem, styles.addresses)}>
                <Image alt="Address" src={smallAddressIcon} width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />
                <div>
                  <p className={styles.detailsContent}>{address.lineOne}</p>
                  <p className={styles.detailsContent}>{address.lineTwo}</p>
                </div>
              </div>
            )}
            <SocialMediaIconList
              facebook={facebook}
              instagram={instagram}
              linkedIn={linkedin}
              twitter={twitter}
              google={google}
              youtube={youtube}
              yelp={yelp}
            />
          </div>
        </div>
        <div className={styles.columnThree}>
          <div className={styles.extraWrapper}>
            <div className={styles.languages}>
              <h4 className={styles.detailsHeading}>Languages Spoken</h4>
              <p className={styles.detailsContent}>{languagesSpoken}</p>
            </div>
            {!!agentLicenses.length && (
              <div className={styles.licenses}>
                <h4 className={styles.detailsHeading}>Agent Licenses & Designations</h4>
                <ul className={classNames({ [styles.licenseList]: agentLicenses.length > 4 })}>
                  {agentLicenses.map(license => (
                    <li key={license} className={styles.detailsContent}>
                      {license}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
