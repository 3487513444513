import { memo, useEffect } from 'react';
import styles from './Footer.module.scss';

export const Footer = memo(({ children, webpageUsername }) => {
  useEffect(() => {
    if (['holdenhouston', 'mitchellhudson'].includes(webpageUsername)) {
      // Dynamically load the script
      const script = document.createElement('script');
      script.src = 'https://js.adsrvr.org/up_loader.1.1.0.js';
      script.type = 'text/javascript';
      script.async = true;
      script.onload = () => {
        if (typeof TTDUniversalPixelApi === 'function') {
          var universalPixelApi = new TTDUniversalPixelApi();
          universalPixelApi.init('z6ng2mv', ['eucjq3d'], 'https://insight.adsrvr.org/track/up');
        }
      };
      document.body.appendChild(script);
    }
  }, [webpageUsername]);

  return (
    <footer className={styles.footerContainer}>
      <h3 className={styles.footerHeader}>Let's Get You Covered</h3>
      <h4 className={styles.footerSubHeader}>
        I'll work with you to find a plan that suits your need - and your budget.
      </h4>
      {children}
    </footer>
  );
});
