import classNames from 'classnames';
import styles from './SubmitButton.module.scss';

export function SubmitButton({ isSubmitting = false }) {
  return (
    <button type="submit" className={classNames(styles.submitButton)} disabled={isSubmitting}>
      <span className={classNames(styles.spinner, { [styles.isSubmitting]: isSubmitting })} />
      <span className={classNames(styles.buttonLabel, { [styles.isSubmitting]: isSubmitting })}>Submit</span>
    </button>
  );
}
