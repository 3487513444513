import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { config } from '@/config';

class Logo extends Component {
  state = {
    mounted: false
  };

  componentDidMount() {
    if (config().RAILS_ENV) this.setState({ mounted: true });
  }

  get logoUrl() {
    return `/static/img/${config().RAILS_ENV}-logo.png`;
  }

  get width() {
    if (this.props.height) {
      if (this.props.width) return this.props.width;
      return '';
    }
    if (this.props.width) return this.props.width;
    if (config().AEGLEADS || config().CAREINADVANCE) {
      return '230';
    } else if (config().MYSIBAGENT) {
      return '90';
    } else {
      return '150';
    }
  }
  get height() {
    if (this.props.width) {
      if (this.props.height) return this.props.height;
      return '';
    }
    if (this.props.height) return this.props.height;
    if (config().MYASBAGENT_SYSTEM || config().ASBAGENTMN || config().MYAIMSAGENT) {
      return '60';
    } else if (config().ASBLEGACY || config().MYASBFINANCIAL || config().CAREINADVANCE) {
      return '50';
    } else {
      return '40';
    }
  }

  get logo() {
    return <img width={this.width} height={this.height} src={this.logoUrl} alt="logo" />;
  }

  render() {
    return this.state.mounted && this.logo;
  }
}

export default withErrorBoundary(Logo);
