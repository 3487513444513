import { memo, useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import blueDropdownIcon from '../../../assets/icons/Blue_Drop_Down_Icon.png';
import styles from './FAQItem.module.scss';

const DEFAULT_ICON_SIZE = 34;

export const FAQItem = memo(({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleFaq = () => setIsOpen(isOpen => !isOpen);

  return (
    <li className={styles.faqItem} onClick={handleToggleFaq}>
      <button className={styles.faqItemInner} type="button">
        <h4 className={styles.faqTitle}>{faq.question}</h4>
        <div className={classNames(styles.faqIcon, { [styles.opened]: isOpen })}>
          <Image alt="View Answer" src={blueDropdownIcon} width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />
        </div>
      </button>
      <div className={classNames(styles.faqBody, { [styles.opened]: isOpen })}>
        <p>{faq.answer}</p>
      </div>
    </li>
  );
});
