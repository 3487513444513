import { memo } from 'react';
import Image from 'next/image';
import classNames from 'classnames';

import ARISLOGO from '../../assets/icons/ARIS_Logo.png';
import styles from './Header.module.scss';
import sharedStyles from '../../FullPage.module.scss';

export const Header = memo(() => {
  return (
    <header className={classNames(sharedStyles.container, styles.header)}>
      <h1>
        <Image alt="Aris Logo" src={ARISLOGO} height={72} className={styles.arisLogo} />
      </h1>
    </header>
  );
});
