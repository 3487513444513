import { useEffect, useRef } from 'react';
import { PatternFormat } from 'react-number-format';
import classNames from 'classnames';
import { BEST_TIMES, PRODUCTS_OFFERED } from '../../utils/constants';
import { useCloseOnEscape } from '../../utils/useCloseOnEscape';
import { useModalForm } from './useModalForm';
import { Disclaimer } from './Disclaimer';
import { SubmitButton } from './SubmitButton';
import styles from './Modals.module.scss';

const initialFormData = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  bestTime: '',
  products: [],
  comments: '',
  disclaimer: false,
  type: 'send_email'
};

export function SendEmailModal({ isOpen, onCreateContact, onCloseModal }) {
  useCloseOnEscape({ isOpen, onCloseModal });

  const firstInputRef = useRef(null);
  useEffect(() => {
    if (isOpen && firstInputRef?.current) {
      firstInputRef.current.focus();
    }
  }, [isOpen]);

  const { formData, errors, isSubmitting, onInputChange, onFormSubmit } = useModalForm({
    initialFormData,
    modalType: 'sendEmailModal',
    onSubmit: onCreateContact,
    onSuccess: onCloseModal
  });

  return (
    <>
      <div className={styles.modalBackdrop} onClick={onCloseModal} />
      <div className={styles.modalContainer}>
        <div className={styles.modalHeaderContainer}>
          <h2 className={styles.modalHeader}>Send Email</h2>
          <button className={styles.modalCloseButton} onClick={onCloseModal}>
            &times;
          </button>
        </div>
        <form onSubmit={onFormSubmit}>
          <div className={styles.modalForm}>
            <div className={styles.modalColumnOne}>
              <input
                ref={firstInputRef}
                aria-label="First Name"
                className={classNames(styles.modalInput, { [styles.inputError]: errors.firstName })}
                placeholder="First Name*"
                name="firstName"
                value={formData.firstName}
                onChange={onInputChange}
              />
              <input
                className={classNames(styles.modalInput, { [styles.inputError]: errors.lastName })}
                aria-label="Last Name"
                placeholder="Last Name*"
                name="lastName"
                value={formData.lastName}
                onChange={onInputChange}
              />
              <PatternFormat
                aria-label="Phone Number"
                className={classNames(styles.modalInput, { [styles.inputError]: errors.phone })}
                format="###-###-####"
                placeholder="Phone Number (###-###-####)"
                name="phone"
                value={formData.phone}
                onChange={onInputChange}
              />
              <input
                aria-label="Email"
                className={classNames(styles.modalInput, { [styles.inputError]: errors.email })}
                placeholder="Email*"
                name="email"
                value={formData.email}
                onChange={onInputChange}
              />
              <div className={styles.bestTimeSelectContainer}>
                <label htmlFor="best_time:" className={styles.selectLabel}>
                  Best Time To Contact You:
                </label>
                <div className={styles.modalSelectWrapper}>
                  <select
                    aria-label="Best Time to Contact"
                    id="best_time"
                    className={classNames(styles.modalSelectInput, { [styles.inputError]: errors.bestTime })}
                    name="bestTime"
                    onChange={onInputChange}
                  >
                    <option key="empty-default" value="">
                      Select...
                    </option>
                    {BEST_TIMES.map(bestTime => (
                      <option key={bestTime.id} value={bestTime.value}>
                        {bestTime.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className={styles.modalColumnTwo}>
              <div className={styles.productsCheckContainer}>
                <label className={styles.selectLabel}>What Products Are You Interested In?*</label>
                <div className={styles.checkboxContainer}>
                  {PRODUCTS_OFFERED.map(product => (
                    <label className={styles.checkboxLabel} key={product.id}>
                      <input
                        aria-label={product.label}
                        type="checkbox"
                        name="products"
                        className={classNames(styles.checkbox, { [styles.inputError]: errors.products })}
                        onChange={onInputChange}
                        value={product.value}
                        checked={formData.products.includes(product.value)}
                      />
                      <span>{product.label}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className={styles.commentsContainer}>
                <label htmlFor="comments" className={styles.selectLabel}>
                  Comments:
                </label>
                <textarea
                  className={styles.comments}
                  name="comments"
                  id="comments"
                  value={formData.comments}
                  onChange={onInputChange}
                />
              </div>
            </div>
          </div>

          <div className={styles.submitContainer}>
            <div className={styles.emptyColumn} />
            <div className={styles.submitButtonContainer}>
              <SubmitButton isSubmitting={isSubmitting} />
            </div>
          </div>
          <Disclaimer value={formData.disclaimer} hasError={errors.disclaimer} onChange={onInputChange} />
        </form>
      </div>
    </>
  );
}
