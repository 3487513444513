import classNames from 'classnames';
import { MODAL_DISCLAIMER } from '../../../utils/constants';
import styles from './Disclaimer.module.scss';

export function Disclaimer({ value, hasError, onChange }) {
  return (
    <div className={styles.disclaimerContainer}>
      <p className={styles.disclaimerContent}>
        <input
          type="checkbox"
          id="disclaimer"
          name="disclaimer"
          aria-label="Disclaimer"
          className={classNames(styles.checkboxSmall, { [styles.inputError]: hasError })}
          checked={value}
          onChange={onChange}
        />
        <label htmlFor="disclaimer" className={styles.disclaimerText}>
          {MODAL_DISCLAIMER}{' '}
          {/* <a href="#" className={styles.privacyStatementLink}>
            Privacy Statement
          </a> */}
        </label>
      </p>
    </div>
  );
}
