import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';
import getConfig from 'next/config';

import { config } from '@/config';

import Webpage from '@/components/Index/Webpage';
import WebpageASB from '@/components/Index/Webpage-ASB';
import WebpageAEG from '@/components/Index/ARIS/Webpage-AEG';
import ForbiddenWebpage from '@/components/Index/ForbiddenWebpage';

const { publicRuntimeConfig } = getConfig();

const Index = ({ webpageUsername }) => {
  const [renderItem, setRenderItem] = useState('');
  let { publicError } = useSelector(state => state.public);

  useEffect(() => {
    if (publicError) return setRenderItem(<ForbiddenWebpage />);

    if (config().ASB_SYSTEM) {
      return setRenderItem(<WebpageASB webpageUsername={webpageUsername} />);
    } else if (config().AEGLEADS) {
      return setRenderItem(<WebpageAEG webpageUsername={webpageUsername} />);
    } else {
      return setRenderItem(<Webpage webpageUsername={webpageUsername} />);
    }
  }, [publicError, webpageUsername]);

  const { info } = useSelector(state => state.public);
  const { corporation, profile } = info;
  const description = profile?.about_me?.replace(/(<([^>]+)>)/gi, '') || '';
  const { name, domain_name } = corporation || {};
  const image = profile?.personal_photo.url;
  const webpage = info.webpage;

  return (
    <>
      <Head>
        {corporation && (
          <>
            <meta name="robots" content="index, follow" />
            <meta name="description" content={description} />
            <meta
              name="keywords"
              content={`${webpageUsername}, ${profile.first_name}, ${profile.last_name}, ${name}, ${domain_name}, medical, insurrance, mad, market, access, direct`}
            />
            <link rel="canonical" href={publicRuntimeConfig.APP_URL} />
            <meta property="og:type" content="article" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:url" content={`//${webpage}`} />
            <meta property="og:title" content={publicRuntimeConfig.PAGE_TITLE} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:site_name" content={publicRuntimeConfig.PAGE_TITLE} />
          </>
        )}
      </Head>
      {renderItem}
    </>
  );
};

export default Index;
