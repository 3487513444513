import { memo } from 'react';
import classNames from 'classnames';
import styles from './SubmitButton.module.scss';

export const SubmitButton = memo(({ isSubmitting = false, isBottom = false }) => {
  return (
    <button
      type="submit"
      className={classNames(styles.submitButton, { [styles.bottom]: isBottom })}
      disabled={isSubmitting}
    >
      <i
        className={classNames('fas fa-circle-notch fa-spin', styles.spinner, {
          [styles.isSubmitting]: isSubmitting
        })}
      />
      <span className={classNames(styles.buttonLabel, { [styles.isSubmitting]: isSubmitting })}>
        Request Appointment
      </span>
    </button>
  );
});
