import { memo } from 'react';
import Image from 'next/image';
import readMoreArrowIcon from '../../../assets/icons/Read_More_Arrow_Icon.png';
import { truncateText } from '../../../utils/formatters';
import styles from './ArticleCard.module.scss';

export const ArticleCard = memo(({ article }) => {
  return (
    <article className={styles.blogCard}>
      <img className={styles.blogCardImage} alt={article.title} src={article.imageUrl} width={360} height={120} />
      <div className={styles.blogCardInner}>
        <div>
          <h3 className={styles.blogCardHeader}>{article.title}</h3>
          <p className={styles.blogCardContent}>{truncateText(article.excerpt)}</p>
        </div>
        <a href={article.articleUrl} className={styles.readMoreLink} target="_blank" rel="noopener noreferrer">
          <span className={styles.readMoreText}>Read more</span>{' '}
          <Image alt="Navigate to External Article" src={readMoreArrowIcon} width={40} height={40} />
        </a>
      </div>
    </article>
  );
});
