import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { setStateAsync } from '@/utils/helpers';

class Submit extends Component {
  state = { disabled: false };
  setStateAsync = setStateAsync.bind(this);

  onClick = async e => {
    e.persist();
    e.preventDefault();

    if (this.state.disabled) {
      return;
    }

    this.setStateAsync({ disabled: true });

    this.props.onSubmit(e, () => {
      this.setState({ disabled: false });
    });
  };

  render() {
    const { disabled } = this.state;
    const { color, className, label, secondLabel, backgroundColor, id } = this.props;

    return (
      <button
        id={id}
        style={{ color: color && color, backgroundColor: backgroundColor && backgroundColor }}
        type="submit"
        className={className}
        disabled={disabled || this.props.disabled}
        onClick={this.onClick}
      >
        {label}
        {secondLabel && <br />}
        {secondLabel && secondLabel}
        {disabled && <em className="fas fa-circle-notch fa-spin ml-1" />}
      </button>
    );
  }
}

export default withErrorBoundary(Submit);
