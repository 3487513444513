import { useEffect } from 'react';

export function useCloseOnEscape({ isOpen, onCloseModal }) {
  useEffect(() => {
    const handleCloseOnEscape = e => {
      if (e.code === 'Escape' || e.key === 'Escape') {
        onCloseModal();
      }
    };
    if (isOpen) {
      window?.addEventListener('keydown', handleCloseOnEscape);
    }
    return () => {
      window?.removeEventListener('keydown', handleCloseOnEscape);
    };
  }, [isOpen, onCloseModal]);
}
