import { useEffect, useRef } from 'react';
import { PatternFormat } from 'react-number-format';
import classNames from 'classnames';
import { useCloseOnEscape } from '../../utils/useCloseOnEscape';
import { useModalForm } from './useModalForm';
import { BEST_TIMES, PRODUCTS_OFFERED } from '../../utils/constants';
import { STATES } from '@/store/models/profile';
import { Disclaimer } from './Disclaimer';
import { SubmitButton } from './SubmitButton';
import styles from './Modals.module.scss';

const initialFormData = {
  firstName: '',
  lastName: '',
  email: '',
  city: '',
  state: '',
  zipCode: '',
  phone: '',
  dob: '',
  bestTime: '',
  products: [],
  comments: '',
  disclaimer: false,
  type: 'get_quote'
};

export function GetQuoteModal({ isOpen, onCreateContact, onCloseModal }) {
  useCloseOnEscape({ isOpen, onCloseModal });

  const firstInputRef = useRef(null);
  useEffect(() => {
    if (isOpen && firstInputRef?.current) {
      firstInputRef.current.focus();
    }
  }, [isOpen]);

  const { formData, errors, isSubmitting, onFormSubmit, onInputChange } = useModalForm({
    initialFormData,
    modalType: 'getQuoteModal',
    onSubmit: onCreateContact,
    onSuccess: onCloseModal
  });

  return (
    <>
      <div className={styles.modalBackdrop} onClick={onCloseModal} />
      <div className={styles.modalContainer}>
        <div className={styles.modalHeaderContainer}>
          <h2 className={styles.modalHeader}>Request a Quote</h2>
          <button className={styles.modalCloseButton} onClick={onCloseModal}>
            &times;
          </button>
        </div>
        <form onSubmit={onFormSubmit}>
          <div className={styles.modalForm}>
            <div className={styles.modalColumnOne}>
              <input
                ref={firstInputRef}
                aria-label="First Name"
                className={classNames(styles.modalInput, {
                  [styles.inputError]: errors.firstName
                })}
                placeholder="First Name*"
                name="firstName"
                value={formData.firstName}
                onChange={onInputChange}
              />
              <input
                className={classNames(styles.modalInput, {
                  [styles.inputError]: errors.lastName
                })}
                aria-label="Last Name"
                placeholder="Last Name*"
                name="lastName"
                value={formData.lastName}
                onChange={onInputChange}
              />
              <input
                className={classNames(styles.modalInput, {
                  [styles.inputError]: errors.city
                })}
                aria-label="City"
                placeholder="City*"
                name="city"
                value={formData.city}
                onChange={onInputChange}
              />
              <div className={classNames(styles.modalSelectWrapper, styles.white)}>
                <select
                  className={classNames(styles.modalSelectInput, styles.white, {
                    [styles.inputError]: errors.state
                  })}
                  aria-label="State"
                  name="state"
                  value={formData.state}
                  onChange={onInputChange}
                >
                  <option value="">State*</option>
                  {STATES.slice(1).map(state => (
                    <option key={state.value} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </select>
              </div>
              <input
                className={classNames(styles.modalInput, {
                  [styles.inputError]: errors.zipCode
                })}
                aria-label="Zip Code"
                placeholder="Zip Code*"
                name="zipCode"
                value={formData.zipCode}
                onChange={onInputChange}
                maxLength={5}
              />
              <PatternFormat
                className={classNames(styles.modalInput, {
                  [styles.inputError]: errors.phone
                })}
                aria-label="Phone Number"
                format="###-###-####"
                placeholder="Phone Number* (###-###-####)"
                name="phone"
                value={formData.phone}
                onChange={onInputChange}
              />
              <PatternFormat
                className={classNames(styles.modalInput, {
                  [styles.inputError]: errors.dob
                })}
                aria-label="Date of Birth"
                format="##/##/####"
                placeholder="Date of Birth* (MM/DD/YYYY)"
                name="dob"
                value={formData.dob}
                onChange={onInputChange}
              />
              <input
                className={classNames(styles.modalInput, {
                  [styles.inputError]: errors.email
                })}
                aria-label="Email"
                placeholder="Email*"
                name="email"
                value={formData.email}
                onChange={onInputChange}
              />
            </div>
            <div className={styles.modalColumnTwo}>
              <div className={styles.bestTimeSelectContainer}>
                <label htmlFor="best_time" className={styles.selectLabel}>
                  Best Time To Contact You:
                </label>
                <div className={styles.modalSelectWrapper}>
                  <select
                    id="best_time"
                    className={classNames(styles.modalSelectInput, { [styles.inputError]: errors.bestTime })}
                    name="bestTime"
                    onChange={onInputChange}
                  >
                    <option value="">Select...</option>
                    {BEST_TIMES.map(bestTime => (
                      <option key={bestTime.id} value={bestTime.value}>
                        {bestTime.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.productsCheckContainer}>
                <label className={styles.selectLabel}>What Products Are You Interested In?*</label>
                <div className={styles.checkboxContainer}>
                  {PRODUCTS_OFFERED.map(product => (
                    <label className={styles.checkboxLabel} key={product.id}>
                      <input
                        type="checkbox"
                        aria-label={product.label}
                        name="products"
                        className={classNames(styles.checkbox, { [styles.inputError]: errors.products })}
                        onChange={onInputChange}
                        value={product.value}
                        checked={formData.products.includes(product.value)}
                      />
                      <span>{product.label}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className={styles.commentsContainer}>
                <label htmlFor="comments" className={styles.selectLabel}>
                  Comments:
                </label>
                <textarea
                  className={styles.comments}
                  name="comments"
                  id="comments"
                  value={formData.comments}
                  onChange={onInputChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.submitContainer}>
            <div className={styles.emptyColumn} />
            <div className={classNames(styles.submitButtonContainer, styles.isCentered)}>
              <SubmitButton isSubmitting={isSubmitting} />
            </div>
          </div>
          <Disclaimer value={formData.disclaimer} hasError={errors.disclaimer} onChange={onInputChange} />
        </form>
      </div>
    </>
  );
}
