import React, { Component, createRef } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { connect } from 'react-redux';
import { PatternFormat } from 'react-number-format';
import classNames from 'classnames';
import { publish } from '@/utils/publish';
import tinycolor from 'tinycolor2';
import { HtmlParser } from '@/utils/helpers';
import { config } from '@/config';
import { PopupWidget } from 'react-calendly';

import styles from './Webpage.module.scss';

import Submit from '@/components/Forms/Submit';
import FormValidator from '@/components/Forms/Validator';
import Notification from '@/components/Base/Notification';

import { TITLE } from '@/store/models/profile';
import { zipFormat } from '@/utils/helpers';
import { webpageContactRequest } from '@/store/actions/public.actions';
import { linkWithHttps } from '@/utils/helpers';
import Footer from './Footer';

class Webpage extends Component {
  initialState = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    text_permission: false
  };

  state = {
    form: this.initialState
  };
  rootRef = createRef();

  async componentDidMount() {
    await this.changeViewport();
    this.forceUpdate();
  }

  changeViewport = () => {
    document.getElementById('viewport')?.setAttribute('content', 'width=device-width');
  };

  get showAris() {
    const { aris } = this.props.info;
    return config().AEGLEADS && aris;
  }

  get title() {
    const result = TITLE.find(({ value }) => value === this.props.info.profile.title);
    return result ? result.label : TITLE[1].label;
  }

  get userProfile() {
    let {
      first_name,
      last_name,
      personal_photo,
      phone,
      cell_phone,
      address_1,
      address_2,
      city,
      state,
      zip_code,
      site_url,
      facebook,
      instagram,
      twitter,
      linkedin
    } = this.props.info.profile;

    const { corporation, email } = this.props.info;

    facebook = linkWithHttps(facebook);
    instagram = linkWithHttps(instagram);
    twitter = linkWithHttps(twitter);
    linkedin = linkWithHttps(linkedin);

    return (
      <div className={styles.profileWrapper}>
        <div className={styles.socials}>
          {(facebook || twitter || linkedin || instagram) && (
            <p
              className={classNames(tinycolor(corporation.color_2).isDark() ? styles.white : '', styles.stayConnected)}
            >
              STAY CONNECTED WITH ME |
            </p>
          )}
          {twitter && (
            <a href={twitter} target="_blank" rel="noreferrer">
              <img
                className={(classNames('social-icon', styles.twitter), styles.socialIcon)}
                src="/static/icons/webpage/twitter.png"
                alt="twitter"
              />
            </a>
          )}
          {facebook && (
            <a href={facebook} target="_blank" rel="noreferrer">
              <img
                className={classNames('social-icon', styles.facebook, styles.socialIcon)}
                src="/static/icons/webpage/facebook.png"
                alt="facebook"
              />
            </a>
          )}
          {linkedin && (
            <a href={linkedin} target="_blank" rel="noreferrer">
              <img
                className={classNames('social-icon', styles.linkedin, styles.socialIcon)}
                src="/static/icons/webpage/linkedin.png"
                alt="linkedin"
              />
            </a>
          )}
          {instagram && (
            <a href={instagram} target="_blank" rel="noreferrer">
              <img
                className={classNames('social-icon', styles.instagra, styles.socialIcon)}
                src="/static/icons/webpage/instagram.png"
                alt="instagram"
              />
            </a>
          )}
        </div>
        <div className={styles.mainInfo}>
          <div className={styles.main}>
            <img className={styles.profileImage} src={personal_photo.url} alt="profile" />
            <div className={styles.description}>
              <h4 className={tinycolor(corporation.color_2).isDark() ? styles.white : styles.black}>
                <b>{[first_name, last_name].join(' ')}</b>
              </h4>
              <p className={tinycolor(corporation.color_2).isDark() ? styles.white : styles.black}>
                <i className={styles.title}>{this.title}</i>
              </p>
            </div>
          </div>

          <div className={styles.addresses} style={{ border: `2px solid ${corporation.color_3}` }}>
            {(address_1 || address_2 || city || zip_code || state) && (
              <div className={styles.infoSection}>
                <p className={tinycolor(corporation.color_2).isDark() ? styles.white : styles.black}>
                  {[[address_1, address_2].filter(el => el).join(', ')].filter(el => el)}
                </p>
                <p className={tinycolor(corporation.color_2).isDark() ? styles.white : styles.black}>
                  {[
                    [city, [state, zipFormat(zip_code)].join(' ')]
                      .filter(el => el?.toString().replace(/\s/g, ''))
                      .join(', ')
                  ].filter(el => el) || '-'}
                </p>
              </div>
            )}

            {(phone || cell_phone) && (
              <div className={styles.infoSection}>
                <p className={tinycolor(corporation.color_2).isDark() ? styles.white : styles.black}>
                  <b>
                    {phone && <i style={{ color: `${corporation.color_3}` }} className="fas fa-phone-alt" />}{' '}
                    {phone || '-'}
                  </b>
                </p>
                <p className={tinycolor(corporation.color_2).isDark() ? styles.white : styles.black}>
                  <b>
                    {cell_phone && <i style={{ color: `${corporation.color_3}` }} className="fas fa-mobile" />}{' '}
                    {cell_phone || '-'}
                  </b>
                </p>
              </div>
            )}

            <div className={styles.infoSection}>
              {site_url && (
                <p className={tinycolor(corporation.color_2).isDark() ? styles.white : styles.black}>
                  Website:{' '}
                  <a
                    className={tinycolor(corporation.color_2).isDark() ? styles.white : styles.black}
                    href={site_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {site_url}
                  </a>
                </p>
              )}
              {email && (
                <a href={`mailto:${email}`}>
                  <p className={tinycolor(corporation.color_2).isDark() ? styles.white : styles.black}>
                    <i style={{ color: `${corporation.color_3}` }} className="fas fa-share" />
                    <b> {email}</b>
                  </p>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  get form() {
    const { first_name, last_name, phone, email, text_permission } = this.state.form;
    const { corporation } = this.props.info;

    return (
      <div style={{ backgroundColor: `${corporation.color_2}` }} className={styles.form}>
        <div className={styles.headerWithIcon}>
          <i style={{ color: `${corporation.color_3}` }} className="fas fa-play-circle" />
          <h5 className={classNames(tinycolor(corporation.color_2).isDark() ? styles.light : '')}>
            Call <b>{this.props.info.profile.phone}</b> or <b>submit this form</b> to schedule your appointment today.
          </h5>
        </div>

        <form autoComplete="off" name="form">
          <div className={styles.flexedFields}>
            <div className={styles.formWrapper}>
              <div className="form-section">
                <div className={classNames('field', styles.field)}>
                  <div className="control">
                    <input
                      id="first_name"
                      onChange={this.validateOnChange}
                      className={classNames(
                        'input is-large',
                        this.hasError('form', 'first_name', 'required') && styles.isDanger
                      )}
                      data-validate='["required"]'
                      name="first_name"
                      placeholder="ENTER FIRST NAME (req.)"
                      maxLength="30"
                      type="text"
                      value={first_name}
                    />
                  </div>
                </div>
              </div>
              <div className="form-section">
                <div className={classNames('field', styles.field)}>
                  <div className="control">
                    <PatternFormat
                      name="phone"
                      format="###-###-####-####"
                      placeholder="ENTER PHONE (req.)"
                      onChange={this.validateOnChange}
                      className={classNames(
                        'input is-large',
                        this.hasError('form', 'phone', 'required') && styles.isDanger
                      )}
                      data-validate='["required"]'
                      value={phone}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.fullWidth}>
              <div className={classNames('field', styles.field)}>
                <div className="control">
                  <input
                    name="last_name"
                    onChange={this.validateOnChange}
                    className={classNames(
                      'input is-large',
                      this.hasError('form', 'last_name', 'required') && styles.isDanger
                    )}
                    data-validate='["required"]'
                    maxLength="30"
                    type="text"
                    placeholder="ENTER LAST NAME (req.)"
                    value={last_name}
                  />
                </div>
              </div>
              <div className={classNames('field', styles.field)}>
                <div className="control">
                  <input
                    name="email"
                    onChange={this.validateOnChange}
                    className={classNames('input is-large', this.hasError('form', 'email', 'email') && styles.isDanger)}
                    type="email"
                    placeholder="ENTER EMAIL (req.)"
                    data-validate='["email"]'
                    value={email}
                  />
                  {this.hasError('form', 'email', 'email') && (
                    <p className={classNames('help is-danger error', styles.isDanger)}>Invalid email address format</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.bottom, styles.flexedFields)}>
            <div className={classNames('field', styles.field, styles.withCheckbox)}>
              <div className={classNames('control flex space-between', styles.flex)}>
                <input
                  id="text_permission"
                  type="checkbox"
                  name="text_permission"
                  className="is-checkradio is-rtl is-large"
                  onChange={this.validateOnChange}
                  checked={text_permission}
                />
                <label htmlFor="text_permission">
                  {' '}
                  <p className={tinycolor(corporation.color_2).isDark() ? styles.white : ''}>TEXT MESSAGES OK</p>
                </label>
              </div>
            </div>

            <div className={styles.submitWrapper}>
              <Submit
                backgroundColor={`${corporation.color_3}`}
                label="SUBMIT TO REQUEST"
                secondLabel="YOUR APPOINTMENT"
                className={classNames('button is-large', tinycolor(corporation.color_3).isLight() ? styles.dark : '')}
                onSubmit={this.onSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }

  get aboutArisAgent() {
    return (
      <div className={styles.aboutMe}>
        Your agent for life. One agent for all your retirement insurance needs. When you partner with an American
        Republic Insurance Services® agent, you gain access to all areas of retirement insurance for seniors. I can
        explain supplemental health insurance, annuities, and life insurance and find plans with the most trusted
        insurance carriers — all in one appointment.
      </div>
    );
  }

  get aboutAris() {
    return (
      <div className={styles.aboutMe}>
        American Republic Insurance Services® (ARIS) is an affiliate of American Enterprise Group, Inc., a financially
        strong organization with companies that have an A (Excellent) rating from AM Best and have been doing business
        since 1929. ARIS isn&apos;t just an agency that provides Medicare supplemental health insurance for seniors.
        ARIS representatives can connect you with life and health insurance for retirement from top carriers.
      </div>
    );
  }

  get userDescription() {
    const {
      profile: { first_name, last_name, about_me },
      corporation
    } = this.props.info;

    return (
      <div className={styles.userDescription}>
        <img className={styles.companyImage} src={corporation.logo.url} alt="info" />
        <h4>
          I&rsquo;m{' '}
          <b>
            {' '}
            {first_name} {last_name}
          </b>
          , licensed {this.title}
          {!config().REMOVED_COMPANY_NAME_WEBPAGE && (
            <>
              {' '}
              with <b>{corporation.name}</b>
            </>
          )}{' '}
          and <b>I&rsquo;m here to help.</b>
        </h4>
        <p className={styles.withSeparator} />
        {!this.isEmptyHTML(about_me) ? (
          <div className={styles.aboutMe}>{HtmlParser(about_me)}</div>
        ) : (
          this.showAris && this.aboutArisAgent
        )}
        <p className={styles.withSeparator} />
        {this.showAris
          ? this.aboutAris
          : corporation.description && <div className={styles.aboutMe}>{HtmlParser(corporation.description)}</div>}
      </div>
    );
  }

  get informationToDownload() {
    const { corporation } = this.props.info;
    return (
      <div
        style={{
          background: `linear-gradient(180deg, ${corporation.color_1} 5%, ${corporation.color_2} 100%)`
        }}
        className={styles.informationDownload}
      >
        <div className={classNames('container custom-container', styles.container)}>
          <div className={styles.filesInfo}>
            <div className={styles.headerWithIcon}>
              <i style={{ color: `${corporation.color_3}` }} className="fas fa-play-circle" />
              <h5 className={classNames(tinycolor(corporation.color_1).isDark() ? styles.light : '')}>
                <b>Free information</b> to download:
              </h5>
            </div>
            <div className={styles.fileList}>
              {corporation.files.length > 0 &&
                corporation.files.map(file => {
                  return (
                    <a key={file.id} href={file.file?.url || file?.file_link} target="_blank" download rel="noreferrer">
                      <div
                        style={
                          corporation.files.length >= 4
                            ? { height: '170px', width: '150px' }
                            : { height: '275px', width: '225px' }
                        }
                        key={file.id}
                        className={styles.fileWrapper}
                      >
                        <div className={styles.info}>
                          <p>{file.file_title}</p>
                        </div>
                        <div className={styles.previewImage}>
                          <img src={file.preview_image.url || '/static/img/no-preview-image.png'} alt="preview file" />
                        </div>

                        <i className={classNames('fas fa-play-circle', styles.download)} />
                      </div>
                    </a>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  isEmptyHTML = html => HtmlParser(html).length <= 0;

  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  onSubmit = async (e, callback) => {
    const form = e.target.form;
    const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));
    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    e.preventDefault();

    if (hasError) {
      return callback();
    }

    const { first_name, last_name, email, phone, text_permission } = this.state[form.name];

    let body = {
      first_name,
      last_name,
      email,
      phone,
      text_permission
    };

    await this.props
      .webpageContactRequest({ domain: this.props.webpageUsername, body })
      .then(() => {
        this.setState({ form: this.initialState });
        publish('success', 'Your request was successfully sent.');
      })
      .catch(res => publish('error', res));

    callback();
  };

  render() {
    const { corporation } = this.props.info;
    const { color_2 } = this.props.info.corporation;

    const isFooter = config().MYMADCRM || config().AEGLEADS;

    return (
      <div
        className={classNames('wrapper font-size-default primary-color-green', styles.webpage)}
        ref={ref => (this.rootRef.current = ref)}
      >
        <div
          style={{
            background: `linear-gradient(180deg, ${corporation.color_2} 5%, ${corporation.color_1} 100%)`
          }}
          className={styles.userWrapper}
        >
          <div className={styles.userInfo}>
            <div className={classNames('container custom-container', styles.container)}>
              <div className={styles.mobileContent}>
                <img className={styles.mobileCompanyImage} src={corporation.logo.url} alt="info" />
              </div>
              <div className={styles.leftContent}>{this.userDescription}</div>
              <div className={styles.rightContent}>{this.userProfile}</div>
            </div>
          </div>
          <div className={styles.headingSection}>
            <div className={classNames('container custom-container', styles.container)}>
              <h4>
                It&apos;s easy to <b>contact me...</b>
              </h4>
              {this.form}
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        {corporation.files.length > 0 && this.informationToDownload}
        <Notification />
        {this.props.info.profile.calendly_link && (
          <PopupWidget
            url={this.props.info.profile.calendly_link}
            rootElement={this.rootRef.current}
            text="Click here to schedule!"
            textColor={tinycolor(color_2).isLight() ? 'black' : 'white'}
            color={color_2}
          />
        )}
        {isFooter && <Footer info={this.props.info} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  info: state.public.info,
  additional_info: state.public
});

export default connect(mapStateToProps, {
  webpageContactRequest
})(withErrorBoundary(Webpage));
