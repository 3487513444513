import { memo } from 'react';
import Image from 'next/image';
import { PRODUCTS_OFFERED } from '../../utils/constants';
import styles from './AboutMe.module.scss';

export const AboutMe = memo(({ aboutMe = '' }) => {
  return (
    <div className={styles.aboutMeContainer}>
      {aboutMe && (
        <>
          <h2 className={styles.aboutMeHeading}>About Me</h2>
          <div className={styles.agentBio} dangerouslySetInnerHTML={{ __html: aboutMe }} />
        </>
      )}
      <div className={styles.productsContainer}>
        <h3 className={styles.productsHeading}>Products Offered</h3>
        <ul className={styles.productsList}>
          {PRODUCTS_OFFERED.map(product => (
            <li className={styles.productItem} key={product.id}>
              <Image alt={`Product ${product.label}`} src={product.icon} width={100} height={100} />
              <p className={styles.productName}>{product.label}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});
