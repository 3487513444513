import { memo, useMemo } from 'react';
import { SocialMediaIcon } from './SocialMediaIcon';
import facebookIcon from '../../assets/icons/Facebook_Icon.png';
import instagramIcon from '../../assets/icons/Instagram_Icon.png';
import linkedInIcon from '../../assets/icons/LinkedIn_Icon.png';
import twitterIcon from '../../assets/icons/Twitter_Icon.png';
import googleIcon from '../../assets/icons/Google_Icon.png';
import youtubeIcon from '../../assets/icons/Youtube_Icon.png';
import yelpIcon from '../../assets/icons/Yelp_Icon.png';

import styles from './SocialMedia.module.scss';

export const SocialMediaIconList = memo(({ facebook, instagram, linkedIn, twitter, google, youtube, yelp }) => {
  const medias = useMemo(
    () =>
      [
        { url: facebook, alt: 'Facebook', icon: facebookIcon },
        { url: instagram, alt: 'Instagram', icon: instagramIcon },
        { url: linkedIn, alt: 'Linked In', icon: linkedInIcon },
        { url: twitter, alt: 'Twitter', icon: twitterIcon },
        { url: google, alt: 'Google My Business', icon: googleIcon },
        { url: youtube, alt: 'Youtube', icon: youtubeIcon },
        { url: yelp, alt: 'Yelp', icon: yelpIcon }
      ].filter(media => !!media.url),
    [facebook, instagram, linkedIn, twitter, google, yelp, youtube]
  );

  const hasMedias = !!medias.length;

  return hasMedias ? (
    <div className={styles.socialMediaIconsContainer}>
      {medias.map(media => (
        <SocialMediaIcon key={media.alt} alt={media.alt} icon={media.icon} url={media.url} />
      ))}
    </div>
  ) : (
    <div className={styles.emptyContainer} />
  );
});
