import annunityIcon from '../assets/icons/Annuity_Icon.png';
import healthInsuranceIcon from '../assets/icons/Health_Insurance_Icon.png';
import lifeInsuranceIcon from '../assets/icons/Life_Insurance_Icon.png';
import medicareIcon from '../assets/icons/Medicare_Icon.png';
import longTermIcon from '../assets/icons/Long_Term_Care_Icon.png';

export const PRODUCTS_OFFERED = Object.freeze([
  { id: 1, value: 'health_insurance', label: 'Health Insurance', icon: healthInsuranceIcon },
  { id: 2, value: 'life_insurance', label: 'Life Insurance', icon: lifeInsuranceIcon },
  { id: 3, value: 'annunity', label: 'Annunity', icon: annunityIcon },
  { id: 4, value: 'medicare_plans', label: 'Medicare Plans', icon: medicareIcon },
  { id: 5, value: 'long_term_care', label: 'Long Term Care', icon: longTermIcon }
]);

export const MODAL_DISCLAIMER = `Checking this box constitutes my written consent to receive marketing communications and related calls and/or text messages from American Republic Insurance Services\u00AE and its affiliates including those made via autodialer, automated technology and/or prerecorded or artifical voice message at the above phone number, even if my number is registered on a Do Not Call Registry. I affirm that I am the regular user of the number provided. I understand that message and data rates may apply, that my consent is not a condition of purchase and that I can revoke my consent at any time.`;

export const BEST_TIMES = Object.freeze([
  { id: 1, value: 'morning', label: 'Morning' },
  { id: 2, value: 'afternoon', label: 'Afternoon' },
  { id: 3, value: 'evening', label: 'Evening' }
]);

export const RESET_FORMS_EVENT = 'RESET_FORMS_EVENT';
