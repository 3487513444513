import { memo } from 'react';
import Image from 'next/image';
import styles from './Banner.module.scss';
import bannerBackground from '../../assets/icons/Landing_Page_Top_Banner.png';

export const Banner = memo(({ agentTitle = '', fullName = '', personalPhotoUrl = '' }) => {
  return (
    <section className={styles.bannerContainer}>
      <div className={styles.banner}>
        <Image
          alt="Banner"
          src={bannerBackground}
          fill={true}
          priority={true}
          className={styles.bannerImage}
          quality={100}
        />
        <div className={styles.profilePhotoContainer}>
          {personalPhotoUrl && <img alt="Agent Photo" src={personalPhotoUrl} className={styles.profilePhoto} />}
        </div>
      </div>
      <div className={styles.agentInfoContainer}>
        <h2 className={styles.agentName}>{fullName}</h2>
        <h3 className={styles.agentTitle}>American Republic Insurance Services&reg; {agentTitle}</h3>
      </div>
    </section>
  );
});
