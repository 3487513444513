import { memo, useEffect } from 'react';
import { subscribe, unsubscribe } from 'pubsub-js';
import classNames from 'classnames';
import { useAppointmentForm } from './useAppointmentForm';
import styles from './Forms.module.scss';
import { ProductSelectInput } from './ProductSelectInput';
import { SubmitButton } from './SubmitButton';
import { RESET_FORMS_EVENT } from '../../utils/constants';

export const TopForm = memo(({ onCreateContact, children }) => {
  const { formData, errors, isSubmitting, onInputChange, onSelectChange, onFormSubmit, resetForm } = useAppointmentForm(
    { onCreateContact }
  );

  useEffect(() => {
    const token = subscribe(RESET_FORMS_EVENT, () => resetForm());

    return () => {
      unsubscribe(token);
    };
  }, []);

  return (
    <form onSubmit={onFormSubmit}>
      <div className={styles.formContainer}>
        <div className={styles.formColumn}>
          <input
            type="text"
            className={classNames(styles.formInput, { [styles.inputError]: errors.firstName })}
            placeholder="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={onInputChange}
            aria-label="First Name"
          />
          <input
            type="text"
            className={classNames(styles.formInput, { [styles.inputError]: errors.lastName })}
            placeholder="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={onInputChange}
            aria-label="Last Name"
          />
        </div>
        <div className={styles.formColumn}>
          <input
            type="text"
            className={classNames(styles.formInput, { [styles.inputError]: errors.email })}
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={onInputChange}
            formNoValidate={true}
            aria-label="Email"
          />
          <ProductSelectInput value={formData.products} onChange={onSelectChange} hasError={errors.products} />
        </div>
      </div>
      <div className={styles.ctaButtonContainer}>
        <SubmitButton isSubmitting={isSubmitting} />
      </div>
      {children}
    </form>
  );
});
