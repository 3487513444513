import { useCallback, useMemo, useState } from 'react';
import { publish as publishFormEvent } from 'pubsub-js';
import { publish } from '@/utils/publish';
import classNames from 'classnames';
import { webpageContactRequest } from '@/store/actions/public.actions';
import { getAgentTitle, getFullAddress, getPhoneNumber, getLanguages, getOfficeHours } from './utils/formatters';
import { RESET_FORMS_EVENT } from './utils/constants';
import { TopForm } from './components/Forms/TopForm';
import { BottomForm } from './components/Forms/BottomForm';
import { GetQuoteModal } from './components/Modals/GetQuoteModal';
import { SendEmailModal } from './components/Modals/SendEmailModal';
import { Header } from './components/Header';
import { Banner } from './components/Banner';
import { CTACards } from './components/CTACards';
import { RequestAppointment } from './components/RequestAppointment';
import { CTACallLink } from './components/RequestAppointment/CTACallLink';
import { AboutMe } from './components/AboutMe';
import { AgentDetails } from './components/AgentDetails';
import { LatestNews } from './components/LatestNews';
import { FAQ } from './components/FAQ';
import { Footer } from './components/Footer';
import styles from './FullPage.module.scss';

export function FullPage({ profileInfo }) {
  const {
    webpageUsername,
    email,
    about_me,
    personal_photo,
    first_name,
    last_name,
    cell_phone,
    phone,
    phone_ext,
    address_1,
    address_2,
    state,
    city,
    zip_code,
    title,
    facebook,
    twitter,
    instagram,
    linkedin,
    google,
    youtube,
    yelp,
    agent_licenses,
    office_hours,
    languages_spoken,
    faqs,
    latest_news
  } = profileInfo;

  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const agentTitle = useMemo(() => getAgentTitle(title), []);
  const agentLicenses = useMemo(() => agent_licenses, []);
  const phoneNumber = useMemo(() => getPhoneNumber({ cell_phone, phone, phone_ext }), []);
  const languagesSpoken = useMemo(() => getLanguages(languages_spoken), []);
  const officeHours = useMemo(() => getOfficeHours(office_hours), []);
  const [address1, address2] = getFullAddress({ address_1, address_2, city, state, zip_code });
  const contactInfo = useMemo(
    () => ({
      email,
      phoneNumber: phoneNumber?.label,
      address: { lineOne: address1, lineTwo: address2 }
    }),
    []
  );
  const socialMediaLinks = useMemo(
    () => ({
      facebook,
      instagram,
      linkedin,
      twitter,
      google,
      youtube,
      yelp
    }),
    []
  );

  const handleSubmit = useCallback(async body => {
    try {
      await webpageContactRequest({ domain: webpageUsername, body })();
      publish('success', 'Your request was successfully sent.');
      if (['holdenhouston', 'mitchellhudson'].includes(webpageUsername)) {
        setTimeout(() => {
          window.location.href = `https://${webpageUsername}.aegleads.com?success=true`;
        }, 4000);
      }
    } catch (ex) {
      publish('error', ex);
    }
    return;
  }, []);

  const handleOpenModal = useCallback(type => {
    const scrollY = window?.scrollY || document?.documentElement?.scrollTop;
    const scrollbarWidth = window?.innerWidth - document?.documentElement.clientWidth;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = `calc(100% - ${scrollbarWidth}px)`;
    document.body.overflow = 'hidden';

    switch (type) {
      case 'quote': {
        publishFormEvent(RESET_FORMS_EVENT);
        setShowQuoteModal(true);
        break;
      }
      case 'email': {
        publishFormEvent(RESET_FORMS_EVENT);
        setShowEmailModal(true);
        break;
      }
      default:
        return;
    }
  }, []);

  const handleCloseModal = useCallback(type => {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '100%';
    document.body.overflow = 'auto';

    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    switch (type) {
      case 'quote': {
        setShowQuoteModal(false);
        break;
      }
      case 'email': {
        setShowEmailModal(false);
        break;
      }
      default:
        return;
    }
  }, []);

  return (
    <>
      <div className={classNames(styles.fullPage)}>
        <Header />
        <Banner
          agentTitle={agentTitle}
          fullName={`${first_name} ${last_name}`}
          personalPhotoUrl={personal_photo?.url}
        />
        <main>
          <section className={styles.container}>
            <CTACards
              phoneNumber={phoneNumber}
              onOpenEmailModal={() => handleOpenModal('email')}
              onOpenQuoteModal={() => handleOpenModal('quote')}
            />
          </section>
          <section className={styles.container}>
            <RequestAppointment>
              <TopForm phoneNumber={phoneNumber} onCreateContact={handleSubmit}>
                {phoneNumber ? <CTACallLink phoneNumber={phoneNumber} /> : null}
              </TopForm>
            </RequestAppointment>
          </section>
          <section className={styles.container}>
            <AboutMe aboutMe={about_me} />
          </section>
          <section className={styles.container}>
            <AgentDetails
              officeHours={officeHours}
              contactInfo={contactInfo}
              socialMedias={socialMediaLinks}
              agentLicenses={agentLicenses}
              languagesSpoken={languagesSpoken}
            />
          </section>
          {Array.isArray(latest_news) && latest_news.length ? <LatestNews items={latest_news} /> : null}
          {Array.isArray(faqs) && faqs.length ? <FAQ faqs={faqs} /> : null}
        </main>
        <Footer webpageUsername={webpageUsername}>
          <BottomForm onCreateContact={handleSubmit} />
        </Footer>
      </div>
      {showEmailModal && (
        <SendEmailModal
          isOpen={showEmailModal}
          onCreateContact={handleSubmit}
          onCloseModal={() => handleCloseModal('email')}
        />
      )}
      {showQuoteModal && (
        <GetQuoteModal
          isOpen={showQuoteModal}
          onCreateContact={handleSubmit}
          onCloseModal={() => handleCloseModal('quote')}
        />
      )}
    </>
  );
}
