import React, { Component, createRef } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { connect } from 'react-redux';
import { PatternFormat } from 'react-number-format';
import classNames from 'classnames';
import { publish } from '@/utils/publish';
import { HtmlParser } from '@/utils/helpers';
import tinycolor from 'tinycolor2';
import { PopupWidget } from 'react-calendly';
import { config } from '@/config';

import styles from './Webpage-ASB.module.scss';
import FormValidator from '@/components/Forms/Validator';
import Notification from '@/components/Base/Notification';
import Submit from '@/components/Forms/Submit';

import { webpageContactRequest, fetchSetting } from '@/store/actions/public.actions';
import Logo from '@/components/Helpers/Logo';
import { TITLE } from '@/store/models/profile';
import { phoneWithUSCountryCode } from '@/utils/helpers';
import { linkWithHttps } from '@/utils/helpers';
import Footer from './Footer';

class Webpage extends Component {
  initialState = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    webpageSmsDisclaimerTitle: '',
    disclaimer: false,
    disclaimerBody:
      'By providing your mobile number you grant permission to be contacted by phone or text message by a licensed insurance agent contracted with American Senior Benefits. You consent to receive recurring automated and conversational messages related to our discussions, including personalized updates and reminders. Consent is not a condition of any purchase. To seek assistance and support, reply with HELP, to conclude the conversation or stop receiving messages, reply with STOP. Message frequency may vary. Standard message and data rates may apply. Review our Terms and Privacy for more information.'
  };

  state = {
    form: this.initialState
  };
  formRef = createRef();
  rootRef = createRef();

  async componentDidMount() {
    this.forceUpdate();
    if (config().ASB_SYSTEM) {
      const webpageSmsDisclaimerTitle = await this.props.fetchSetting('webpage_sms_disclaimer_title');
      this.setState({ webpageSmsDisclaimerTitle: webpageSmsDisclaimerTitle?.data?.value || '' });
    }
  }

  previewTitle = value => {
    return TITLE.find(el => el.value === value)?.label;
  };

  get header() {
    const { phone } = this.props.info.profile;
    const { color_2 } = this.props.info.corporation;

    return (
      <div className={styles.wrapper}>
        <div className={classNames(styles.content, styles.header)}>
          <div className={styles.companyImageWrapper}>
            {this.props.info.corporation.logo.url ? (
              <img
                className={styles.companyImage}
                src={this.props.info.corporation.logo.url}
                alt={'corporation_logo'}
              />
            ) : (
              <Logo height={'121'} />
            )}
          </div>
          <div className={styles.callMeWrapper}>
            <div className={styles.callMe}>
              {phone && <img src="/static/icons/webpage/phone-icon.png" alt="phone-icon" />}
              {phone ? (
                <span>
                  Call me today at{' '}
                  <span
                    style={{
                      color: color_2 !== '#ffffff' && color_2 !== '#fff' && `${this.props.info.corporation.color_2}`
                    }}
                    className={styles.blackText}
                  >
                    {phone}
                  </span>
                </span>
              ) : (
                <span>Contact us today</span>
              )}
            </div>
            <button
              className={tinycolor(color_2).isLight() ? styles.black : styles.white}
              style={{ background: `${color_2}` }}
              onClick={() => {
                this.formRef.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                });
              }}
            >
              CONTACT
            </button>
          </div>
        </div>
      </div>
    );
  }

  get strip() {
    return <div style={{ background: `${this.props.info.corporation.color_1}` }} className={styles.strip} />;
  }

  get contactInfo() {
    let {
      first_name,
      last_name,
      personal_photo,
      phone,
      address_1,
      address_2,
      city,
      state,
      zip_code,
      title,
      facebook,
      instagram,
      twitter,
      linkedin,
      medicare_center_webpage_link
    } = this.props.info.profile;
    const { email } = this.props.info;
    const { color_2 } = this.props.info.corporation;

    facebook = linkWithHttps(facebook);
    instagram = linkWithHttps(instagram);
    twitter = linkWithHttps(twitter);
    linkedin = linkWithHttps(linkedin);
    medicare_center_webpage_link = linkWithHttps(medicare_center_webpage_link);

    return (
      <div className={styles.wrapper}>
        <div className={classNames(styles.content, styles.contactInfo)}>
          <div className={styles.infoWrapper}>
            <div className={styles.info}>
              <div
                style={{
                  color: color_2 !== '#ffffff' && color_2 !== '#fff' && `${color_2}`
                }}
                className={styles.agentName}
              >
                {first_name} {last_name}
              </div>
              <div className={styles.address}>
                <div className={styles.companyName}>{this.previewTitle(title)}</div>
                {address_1 && <div>{address_1}</div>}
                {address_2 && <div>{address_2}</div>}
                <div>
                  {city}
                  {city && (state || zip_code) && ','}
                  {state && ' ' + state + ' '}
                  {zip_code}
                </div>
              </div>
            </div>
            <div className={styles.profileImageWrapper}>
              <img className={styles.profileImage} src={personal_photo.url} alt="profile" />
              <div className={styles.profileImageAgentName}>
                {first_name} {last_name}
              </div>
            </div>
          </div>
          <div className={styles.infoBlocks}>
            <div className={styles.infoBlock}>
              <div>
                <i
                  style={{
                    color: color_2 !== '#ffffff' && color_2 !== '#fff' && `${color_2}`
                  }}
                  className="fas fa-share"
                />
              </div>
              <div>
                {email ? (
                  <a href={`mailto:${email}?subject=Hello ${first_name} ${last_name}`}>Email Me</a>
                ) : (
                  <div>Email Me</div>
                )}
              </div>
            </div>
            <div className={styles.infoBlock}>
              <div>
                <i
                  style={{
                    color: color_2 !== '#ffffff' && color_2 !== '#fff' && `${color_2}`
                  }}
                  className="fas fa-phone-alt"
                />
              </div>
              <div>{phone ? <a href={'tel:+' + phoneWithUSCountryCode(phone)}>{phone}</a> : <div>Phone Us</div>}</div>
            </div>
            <div className={styles.socials}>
              {twitter && (
                <a href={twitter} target="_blank" rel="noreferrer">
                  <img
                    className={(classNames('social-icon', styles.twitter), styles.socialIcon)}
                    src="/static/icons/webpage/twitter.png"
                    alt="twitter"
                  />
                </a>
              )}
              {facebook && (
                <a href={facebook} target="_blank" rel="noreferrer">
                  <img
                    className={classNames('social-icon', styles.facebook, styles.socialIcon)}
                    src="/static/icons/webpage/facebook.png"
                    alt="facebook"
                  />
                </a>
              )}
              {linkedin && (
                <a href={linkedin} target="_blank" rel="noreferrer">
                  <img
                    className={classNames('social-icon', styles.linkedin, styles.socialIcon)}
                    src="/static/icons/webpage/linkedin.png"
                    alt="linkedin"
                  />
                </a>
              )}
              {instagram && (
                <a href={instagram} target="_blank" rel="noreferrer">
                  <img
                    className={classNames('social-icon', styles.instagra, styles.socialIcon)}
                    src="/static/icons/webpage/instagram.png"
                    alt="instagram"
                  />
                </a>
              )}
            </div>
            {medicare_center_webpage_link && (
              <a
                className={styles.medicareCenterWebpageLink}
                href={medicare_center_webpage_link}
                target="_blank"
                rel="noreferrer"
              >
                <button
                  className={tinycolor(color_2).isLight() ? styles.black : styles.white}
                  style={{ background: `${color_2}` }}
                >
                  Shop for Plans Now
                </button>
              </a>
            )}
          </div>
          <hr />
        </div>
      </div>
    );
  }

  get aboutMe() {
    const { color_1 } = this.props.info.corporation;

    return (
      <div style={{ background: `${color_1}` }} className={styles.aboutMeWrapper}>
        <div style={{ background: `${color_1}` }} className={classNames(styles.content, styles.aboutMe)}>
          {this.props.info.profile.about_me && (
            <>
              <div className={styles.title}>
                <span className={tinycolor(color_1).isLight() ? styles.black : styles.white}>About Me</span>
              </div>
              <div className={styles.text}>
                <span className={tinycolor(color_1).isLight() ? styles.black : styles.white}>
                  {HtmlParser(this.props.info.profile.about_me)}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  get form() {
    const { first_name, last_name, phone, email } = this.state.form;
    const { color_2 } = this.props.info.corporation;

    return (
      <div className={classNames(styles.wrapper, styles.grey)}>
        <div className={classNames(styles.content)}>
          <div className={classNames(styles.cards, styles.grey)}>
            <div className={styles.card}>
              <div className={styles.infoTitle}>Protecting families like yours for years.</div>
              <div className={styles.infoText}>{HtmlParser(this.props.info.corporation.description)}</div>
            </div>

            <form autoComplete="off" name="form" id="form" ref={this.formRef} className={styles.card}>
              <div className={styles.cardContainer}>
                <div
                  style={{
                    color: color_2 !== '#ffffff' && color_2 !== '#fff' && `${color_2}`
                  }}
                  className={styles.formTitle}
                >
                  Schedule a Free Consultation
                </div>
                <div className={styles.formSubtitle}>- No obligation whatsoever -</div>
                <div className={styles.formDescription}>
                  Schedule a <span className={styles.uppercase}>free</span> consultation to explore your insurance
                  options
                </div>

                <div className={styles.formInputs}>
                  <div className={styles.nameContainer}>
                    <input
                      id="first_name"
                      onChange={this.validateOnChange}
                      className={classNames(
                        'input is-large',
                        this.hasError('form', 'first_name', 'required') && styles.isDanger
                      )}
                      data-validate='["required"]'
                      name="first_name"
                      placeholder="First Name"
                      maxLength="30"
                      type="text"
                      value={first_name}
                    />
                    <input
                      name="last_name"
                      onChange={this.validateOnChange}
                      className={classNames(
                        'input is-large',
                        this.hasError('form', 'last_name', 'required') && styles.isDanger
                      )}
                      data-validate='["required"]'
                      maxLength="30"
                      type="text"
                      placeholder="Last Name"
                      value={last_name}
                    />
                  </div>
                  <PatternFormat
                    name="phone"
                    format="### ### #### ####"
                    placeholder="Mobile Number"
                    onChange={this.validateOnChange}
                    className={classNames(
                      'input is-large',
                      this.hasError('form', 'phone', 'required') && styles.isDanger
                    )}
                    data-validate='["required"]'
                    value={phone}
                  />
                  <input
                    name="email"
                    onChange={this.validateOnChange}
                    className={classNames('input is-large', this.hasError('form', 'email', 'email') && styles.isDanger)}
                    type="email"
                    placeholder="E-Mail Address"
                    data-validate='["email"]'
                    value={email}
                  />
                  {this.hasError('form', 'email', 'email') && (
                    <p className={classNames('help is-danger error', styles.isDanger)}>Invalid email address format</p>
                  )}
                </div>
                {this.disclaimer}

                <Submit
                  backgroundColor={`${color_2}`}
                  label="SUBMIT"
                  className={tinycolor(color_2).isLight() ? styles.black : styles.white}
                  onSubmit={this.onSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  get disclaimer() {
    const { disclaimerBody } = this.state.form;

    return (
      <div className={styles.disclaimer}>
        <label className={classNames(styles.customCheckbox)}>
          <input
            type="checkbox"
            name="disclaimer"
            data-validate='["required"]'
            className={classNames(styles.checkbox)}
            checked={this.state.form.disclaimer}
            onChange={this.validateOnChange}
          />
          <span
            className={classNames(
              styles.checkmark,
              this.hasError('form', 'disclaimer', 'required') ? styles.isCheckDanger : ''
            )}
          />
        </label>
        <p className={styles.content}>{disclaimerBody}</p>
      </div>
    );
  }

  get downloadInformation() {
    const { color_1 } = this.props.info.corporation;

    return (
      <div className={styles.wrapper}>
        <div className={classNames(styles.content, styles.downloadInformation)}>
          <div
            style={{ color: color_1 !== '#ffffff' && color_1 !== '#fff' && `${color_1}` }}
            className={styles.downloadTitle}
          >
            Free information for download:
          </div>
          <hr className={styles.hr} />
          <div className={styles.downloadInfoWrapper}>
            {this.props.info.corporation.files.map(file => (
              <div key={file.id} className={styles.downloadInfoImg}>
                <img
                  src={file.preview_image.url ? file.preview_image.url : 'static/img/no-preview-image.png'}
                  alt="file-preview"
                />
                <div className={styles.text}>{file.file_title}</div>
                <a href={file.file?.url || file?.file_link} download target="_blank" rel="noreferrer">
                  <img src="static/icons/webpage/download.png" alt="Download" />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  get footerStrip() {
    return <div style={{ background: `${this.props.info.corporation.color_1}be` }} className={styles.footerStrip} />;
  }

  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  onError(e) {
    publish('error', e);
  }

  onSubmit = async (e, callback) => {
    const form = e.target.form;
    const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    e.preventDefault();

    if (hasError) {
      return callback && callback();
    }

    const { first_name, last_name, email, phone, disclaimer, disclaimerBody } = this.state[form.name];

    let body = {
      first_name: first_name.trim(),
      last_name: last_name.trim(),
      email: email.trim(),
      phone: phone.trim(),
      text_permission: disclaimer,
      disclaimer_body: disclaimerBody.trim()
    };

    await this.props
      .webpageContactRequest({ domain: this.props.webpageUsername, body })
      .then(() => {
        this.setState({ form: this.initialState });
        publish('success', 'Your request was successfully sent.');
      })
      .catch(res => publish('error', res));

    callback && callback();
  };

  render() {
    const { color_2 } = this.props.info.corporation;

    return (
      <div ref={ref => (this.rootRef.current = ref)}>
        {this.header}
        {this.strip}
        {this.contactInfo}
        {this.aboutMe}
        {this.form}
        {this.downloadInformation}
        {this.footerStrip}
        <Footer info={{ ...this.props.info, webpageSmsDisclaimerTitle: this.state.webpageSmsDisclaimerTitle }} />
        <Notification classNames={styles.notification} />
        {this.props.info.profile.calendly_link && (
          <PopupWidget
            url={this.props.info.profile.calendly_link}
            rootElement={this.rootRef.current}
            text="Click here to schedule!"
            textColor={tinycolor(color_2).isLight() ? 'black' : 'white'}
            color={color_2}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  info: state.public.info,
  additional_info: state.public
});

export default connect(mapStateToProps, {
  webpageContactRequest,
  fetchSetting
})(withErrorBoundary(Webpage));
