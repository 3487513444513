import { memo, useEffect } from 'react';
import { subscribe, unsubscribe } from 'pubsub-js';
import classNames from 'classnames';
import { RESET_FORMS_EVENT } from '../../utils/constants';
import { useAppointmentForm } from './useAppointmentForm';
import styles from './Forms.module.scss';
import { ProductSelectInput } from './ProductSelectInput';
import { SubmitButton } from './SubmitButton';

export const BottomForm = memo(({ onCreateContact }) => {
  const { formData, errors, isSubmitting, onInputChange, onSelectChange, onFormSubmit, resetForm } = useAppointmentForm(
    { onCreateContact }
  );

  useEffect(() => {
    const token = subscribe(RESET_FORMS_EVENT, () => resetForm());

    return () => {
      unsubscribe(token);
    };
  }, []);

  return (
    <form onSubmit={onFormSubmit} className={styles.bottomFormContainer}>
      <input
        type="text"
        aria-label="First Name"
        className={classNames(styles.formInput, { [styles.inputError]: errors.firstName })}
        placeholder="First Name"
        name="firstName"
        value={formData.firstName}
        onChange={onInputChange}
      />
      <input
        type="text"
        aria-label="Last Name"
        className={classNames(styles.formInput, { [styles.inputError]: errors.lastName })}
        placeholder="Last Name"
        name="lastName"
        value={formData.lastName}
        onChange={onInputChange}
      />
      <input
        type="text"
        aria-label="Email"
        className={classNames(styles.formInput, { [styles.inputError]: errors.email })}
        placeholder="Email"
        name="email"
        value={formData.email}
        onChange={onInputChange}
      />
      <ProductSelectInput
        value={formData.products}
        hasError={errors.products}
        onChange={onSelectChange}
        isBottom={true}
      />
      <SubmitButton isSubmitting={isSubmitting} isBottom={true} />
    </form>
  );
});
