import { memo } from 'react';
import { FAQItem } from './FAQItem';
import styles from './FAQ.module.scss';

export const FAQ = memo(({ faqs = [] }) => {
  return (
    <section className={styles.faqContainer}>
      <h3 className={styles.faqHeader}>Frequently Asked Questions</h3>
      <ul className={styles.faqsInner}>
        {faqs.map((faq, index) => (
          <FAQItem key={faq?.id || index} faq={faq} />
        ))}
      </ul>
    </section>
  );
});
