import { memo } from 'react';
import Image from 'next/image';

export const SocialMediaIcon = memo(({ alt, icon, url }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Image alt={alt} src={icon} width={34} height={34} />
    </a>
  );
});
