import { memo } from 'react';
import Image from 'next/image';
import emailIcon from '../../assets/icons/Email_Icon.png';
import phoneIcon from '../../assets/icons/Phone_Icon.png';
import quoteOnlineIcon from '../../assets/icons/Get_A_Quote_Icon.png';
import styles from './CTACards.module.scss';

const DEFAULT_ICON_SIZE = 80;

export const CTACards = memo(({ onOpenEmailModal, onOpenQuoteModal, phoneNumber }) => {
  return (
    <section className={styles.cardsContainer}>
      <button type="button" className={styles.ctaCard} onClick={onOpenEmailModal}>
        <Image alt="Send Email" src={emailIcon} width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />
        <p>Email</p>
      </button>
      {phoneNumber && (
        <a className={styles.ctaCard} href={`tel:${phoneNumber?.anchor}`}>
          <Image alt="Make a Phone Call" src={phoneIcon} width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />
          <p>Call</p>
        </a>
      )}
      <button type="button" className={styles.ctaCard} onClick={onOpenQuoteModal}>
        <Image alt="Get a Quote" src={quoteOnlineIcon} width={DEFAULT_ICON_SIZE} height={DEFAULT_ICON_SIZE} />
        <p className={styles.getQuoteLabel}>Get a Quote Online</p>
      </button>
    </section>
  );
});
