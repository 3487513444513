import moment from 'moment';

export const getAgentTitle = title => {
  switch (title) {
    case 'representative':
      return 'Representative';
    case 'insurance_agent':
      return 'Insurance Agent';
    case 'regional_sales_manager':
      return 'Regional Sales Manager';
    case 'field_manager':
      return 'Field Manager';
    case 'district_manager':
      return 'District Manager';
    case 'advisor':
      return 'Advisor';
    case 'retirement_specialist':
      return 'Retirement Specialist';
    case '':
      return '';
    default:
      return title?.replace(/_/g, ' ');
  }
};

export const getPhoneNumber = ({ cell_phone, phone, phone_ext }) => {
  if (!cell_phone && !phone) {
    return null;
  }
  if (cell_phone) {
    return { label: cell_phone, anchor: `+1${cell_phone.replace(/-/g, '')}` };
  }
  if (phone) {
    return phone_ext
      ? { label: `${phone} x${phone_ext}`, anchor: `+1${phone.replace(/-/g, '')};ext=${phone_ext.replace(/-/g, '')}` }
      : { label: `${phone}`, anchor: `+1${phone.replace(/-/g, '')}` };
  }
};

export const getLanguages = languages_spoken => {
  return languages_spoken.length ? languages_spoken?.join(', ') : 'English';
};

export const getOfficeHours = office_hours => {
  const DAYS = Object.freeze(['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun']);
  const getDayIndex = day => DAYS.indexOf(day);
  const convertTo12HourFormat = time => moment(time, 'HH:mm').format('h:mma');

  const sortDays = officeHours => {
    return officeHours.map(entry => ({
      days: [...entry.days].sort((a, b) => getDayIndex(a) - getDayIndex(b)),
      timeRange: { ...entry.timeRange }
    }));
  };

  const splitSequentialDays = schedule => {
    // Extract days and timeRange from the schedule object
    const { days, timeRange } = schedule;
    // Initialize result array
    let result = [];

    // Temporary array to hold sequential days
    let tempArray = [days[0]];

    // Iterate over the sorted days array starting from the second element
    for (let i = 1; i < days.length; i++) {
      // Check if the current day follows the previous day sequentially
      if (getDayIndex(days[i]) === getDayIndex(days[i - 1]) + 1) {
        tempArray.push(days[i]);
      } else {
        // If not sequential, push the tempArray to result and start a new one
        result.push(tempArray);
        tempArray = [days[i]];
      }
    }

    // Push the last tempArray to result if it's not already included
    result.push(tempArray);

    // Construct the output object
    const output = { days: result.length === 1 ? days : result, timeRange };

    return output;
  };

  const groupOfficeHours = schedule => {
    const result = [];
    schedule.forEach(entry => {
      const groupedDays = splitSequentialDays(entry);

      if (groupedDays.days.every(group => Array.isArray(group))) {
        groupedDays.days.forEach(group => {
          result.push({ days: group, timeRange: groupedDays.timeRange });
        });
      } else {
        result.push(groupedDays);
      }
    });
    return result;
  };

  const addClosedDays = schedule => {
    const closedDays = { days: [], timeRange: { start: '', end: '' } };
    const allDaysListed = [...new Set(schedule.map(entry => entry.days).flat())];
    DAYS.forEach(day => {
      if (!allDaysListed.includes(day)) {
        closedDays.days.push(day);
      }
    });
    const result = groupOfficeHours([closedDays]);
    return result;
  };

  const sortMerged = schedule => {
    const dayIndex = DAYS.reduce((acc, day, index) => {
      acc[day] = index;
      return acc;
    }, {});

    // Helper function to convert time string to comparable moment object
    const timeToMoment = time => {
      if (!time) return moment('23:59', 'HH:mm'); // Treat empty times as very late (almost midnight)
      return moment(time, 'HH:mm');
    };

    // Sort the array based on the first day of the 'days' array, the number of days, and the start time
    return schedule.sort((a, b) => {
      const firstDayA = dayIndex[a.days[0]];
      const firstDayB = dayIndex[b.days[0]];
      if (firstDayA !== firstDayB) {
        return firstDayA - firstDayB;
      }
      // If the first day is the same, sort by the length of the 'days' array
      const lengthDifference = b.days.length - a.days.length;
      if (lengthDifference !== 0) {
        return lengthDifference;
      }
      // If the first day and the length are the same, sort by start time
      const startTimeA = timeToMoment(a.timeRange.start);
      const startTimeB = timeToMoment(b.timeRange.start);
      return startTimeA - startTimeB;
    });
  };

  const formatOfficeHours = schedule => {
    const formatted = schedule.map(entry => {
      const isClosed = !entry.timeRange.start && !entry.timeRange.end;
      if (entry.days.length > 1) {
        return {
          days: `${entry.days[0]} - ${entry.days[entry.days.length - 1]}`,
          timeRange: isClosed
            ? 'Closed'
            : `${convertTo12HourFormat(entry.timeRange.start)} – ${convertTo12HourFormat(entry.timeRange.end)}`
        };
      } else {
        return {
          days: entry.days[0],
          timeRange: isClosed
            ? 'Closed'
            : `${convertTo12HourFormat(entry.timeRange.start)} – ${convertTo12HourFormat(entry.timeRange.end)}`
        };
      }
    });
    return formatted;
  };

  const grouped = groupOfficeHours(sortDays(office_hours));
  const mergedWithClosed = [...grouped, ...addClosedDays(grouped)];
  const sorted = sortMerged(mergedWithClosed);

  return formatOfficeHours(sorted);
};

export const getFullAddress = ({ address_1, address_2, city, state, zip_code }) => {
  const lineOne = `${address_1 || ''}${address_2 ? ',' : ''} ${address_2 || ''}`;
  const lineTwo = `${city || ''}${city ? ',' : ''} ${state || ''} ${zip_code || ''}`;

  return [lineOne.trim(), lineTwo.trim()];
};

export const truncateText = (text = '', maxLength = 131) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return `${text}...`;
};

export const capitalizeFirstLetter = (text = '') => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const formatPhone = phone => {
  if (!phone) {
    return null;
  }
  let digits = phone.replace(/\D/g, '');

  if (digits.length === 11 && digits.startsWith('1')) {
    digits = digits.slice(1);
  }

  return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
};

export const formatDate = (date = '') => {
  return date.replace(/[- ]/g, '/');
};

export const camelToSnake = str => {
  return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};
